import { Index, useInfiniteHits } from "react-instantsearch";
import { useTranslation } from "react-i18next";
import { useDocumentTitle } from "hooks/useDocumentTitle";
import { useOceanViewColumns } from "features/OceanView/hooks/useOceanViewColumns";
import { FeatureFlagNames, useFeatureFlags } from "hooks/useFeatureFlags";
import ActionButtons from "components/ActionButtons";
import AlgoliaConfigure from "components/AlgoliaConfigure/AlgoliaConfigure";
import ArchivedButton from "components/AlgoliaArchivedButton";
import Search from "components/Search";
import CargoUnitCard from "features/CargoUnitCard";
import FilterDropdown from "components/FilterDropdown";
import { ALGOLIA_INDEXES, IndexNameProvider } from "hooks/useIndexName";
import { JOURNEY_VIEWS, JourneyViewProvider } from "hooks/useJourneyView";
import CurrentFilters from "components/CurrentFilters";
import JourneysTable from "components/JourneysTable";
import { sanitizeAlgoliaCargoUnits } from "utils/sanitizeAlgoliaRecords";
import SearchPagination from "components/SearchPagination";
import { attributesDisplay, filterAttributes } from "features/OceanView/constants/filters";
import UploadDocumentsButton from "features/ManualUpload/components/ManualUploadButton";
import UploadDocumentsDrawer from "features/ManualUpload/components/ManualUploadDrawer";
import ExcelExportButton from "components/ExcelExportButton";

const OCEAN_FILTER_CONFIG =
  "AND (entryModeOfTransport:ocean OR currentModeOfTransport:ocean OR latestOceanMilestone:'Awaiting Data')";
const AlgoliaOceanViewTable = () => {
  const { items } = useInfiniteHits();
  const columns = useOceanViewColumns();
  const {
    i18n: { language },
  } = useTranslation();

  const cargoUnitRecords = sanitizeAlgoliaCargoUnits(items, language);

  const featureFlags = useFeatureFlags();
  const disablePurchaseOrderColumn = featureFlags[FeatureFlagNames.DisablePurchaseOrderColumn];
  const columnVisibilityOverrides = { purchaseOrderNumbers: !disablePurchaseOrderColumn };

  return (
    <>
      <JourneysTable
        journeys={cargoUnitRecords}
        columns={columns}
        columnVisibilityOverrides={columnVisibilityOverrides}
      />
      <SearchPagination />
    </>
  );
};

const OceanView = () => {
  const { t } = useTranslation();
  const featureFlags = useFeatureFlags();

  useDocumentTitle(t("Ocean View"));

  return (
    <JourneyViewProvider value={JOURNEY_VIEWS.OCEAN}>
      <IndexNameProvider value={ALGOLIA_INDEXES.JOURNEY}>
        <Index indexName={ALGOLIA_INDEXES.JOURNEY}>
          <AlgoliaConfigure filters={OCEAN_FILTER_CONFIG} />
          <ActionButtons title="Ocean">
            <Search />
            <FilterDropdown filterAttributes={filterAttributes} />
            <ArchivedButton />
            <ExcelExportButton />
            {featureFlags[FeatureFlagNames.EnableJourneyManualUpload] && <UploadDocumentsButton />}
          </ActionButtons>
          <CurrentFilters attributesDisplay={attributesDisplay} />
          <AlgoliaOceanViewTable />
          <CargoUnitCard milestoneModeOfTransportFilter={["ocean"]} />
          <UploadDocumentsDrawer />
        </Index>
      </IndexNameProvider>
    </JourneyViewProvider>
  );
};

export default OceanView;
