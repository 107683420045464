import config from "config";
import { useTranslation } from "react-i18next";
import { useDocumentTitle } from "hooks/useDocumentTitle";
import Dashboard from "components/MetabaseDashboard";
import { Container } from "./Inisights.styled";

const { METABASE_JOURNEY_DASHBOARD_ID, METABASE_JOURNEY_SPANISH_DASHBOARD_ID } = config.envs;

const getDashboardId = (language: string): number => {
  return Number(
    language === "es" ? METABASE_JOURNEY_SPANISH_DASHBOARD_ID : METABASE_JOURNEY_DASHBOARD_ID,
  );
};

const Insights = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  useDocumentTitle(t("Insights"));

  const dashboardId = getDashboardId(language);

  return (
    <Container data-testid="insights-page">
      <h1>{t("Insights")}</h1>
      <Dashboard dashboardId={dashboardId} />
    </Container>
  );
};

export default Insights;
