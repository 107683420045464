import { liteClient as algoliasearch } from "algoliasearch/lite";
import { useQuery } from "@tanstack/react-query";
import { InstantSearch } from "react-instantsearch";
import { getAlgoliaSearchAPIKey } from "services/api/search";
import config from "config";

const { ALGOLIA_APP_ID } = config.envs;
const SEARCH_DELAY_MS = 500;

type AlgoliaSearchWrapperProps = {
  children: React.ReactNode;
};

const AlgoliaSearchWrapper = ({ children }: AlgoliaSearchWrapperProps) => {
  const {
    data: algoliasSearchAPIKey,
    isLoading: algoliasSearchAPIKeyIsLoading,
    isError: algoliasSearchAPIKeyIsError,
  } = useQuery({
    queryKey: ["algoliasSearchAPIKey"],
    queryFn: () => getAlgoliaSearchAPIKey(),
  });

  if (algoliasSearchAPIKeyIsLoading || algoliasSearchAPIKeyIsError) {
    return null;
  }

  // TODO: Make this a singleton so searchClient instance is not recreated on tenant change
  // https://www.algolia.com/doc/api-reference/widgets/instantsearch/react/#widget-param-searchclient
  const searchClient = algoliasearch(ALGOLIA_APP_ID, algoliasSearchAPIKey);

  return (
    <InstantSearch
      searchClient={searchClient}
      routing={true}
      stalledSearchDelay={SEARCH_DELAY_MS} // allow a beat for searchValue states to apply before refreshing
      future={{
        preserveSharedStateOnUnmount: false,
      }}
    >
      {children}
    </InstantSearch>
  );
};

export default AlgoliaSearchWrapper;
